import React, { lazy, Suspense, useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from "react-router-dom";

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import MKBox from "components/MKBox";
import Button from '@mui/material/Button';
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from '../AinaHome/NavbarTest';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import AinaCarRent1 from "assets/images/ainacar-rent-a-car.jpg";
import logoprotect from "assets/images/logoprotect.png";
import Divider from '@mui/material/Divider';
import protect1 from "assets/images/fleet.webp";
import protect2 from "assets/images/protect02.webp";
import protect3 from "assets/images/protect03.webp";
import protect4 from "assets/images/protect04.webp";
import imagenProtect01 from "assets/images/imagenprotect01.jpeg";
import imagenProtect02 from "assets/images/imagenprotect02.jpeg";
import imagenProtect03 from "assets/images/imagenprotect03.jpg";
import imagenProtect04 from "assets/images/imagenprotect04.jpg";
import imagenProtect05 from "assets/images/imagenprotect05.jpg";
import imagenProtect06 from "assets/images/imagenprotect06.jpeg";
import imagenProtect07 from "assets/images/imagenprotect07.jpg";
import imagenProtect08 from "assets/images/imagenprotect08.jpg";
import footerRoutes from "footer.routes";
import Container from '@mui/material/Container';
import Whatsapp from '../AinaHome/whatsapp';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Karve02 from '../AinaHome/karve02';
import Productos from './productos';
import Footer from '../AinaHome/footer';
import expocar01 from 'assets/images/ainacar-bola-remolque.png';
import expocar02 from 'assets/images/RENAULTTRAFIC2.png';
import expocar03 from 'assets/images/ainacar-coches-suv.png';
import expocar04 from 'assets/images/ainacar-furgonetas-camiones (1).png';

const DesktopDrawer = lazy(() => import('../AinaHome/DesktopDrawer'));
const MobileDrawer = lazy(() => import('../AinaHome/MobileDrawer'));

const images = [
  imagenProtect01, imagenProtect02, imagenProtect03, imagenProtect04,
  imagenProtect05, imagenProtect06, imagenProtect07, imagenProtect08,
];

const imagesBackground = [
  protect1, protect2, protect3, protect4
];

function CustomCard() {
  const { t } = useTranslation();

  return (
    <Card 
      sx={{ 
        backgroundColor: '#d6061e', 
        boxShadow: 'none', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        pt: 2 
      }}
    >
      <CardContent 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          textAlign: 'center' 
        }}
      >
        <MKTypography variant="body2" color="white">
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim01')}</span>
          {t('fleet_veh_bola_claim02')}
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim03')}</span>
          {t('fleet_veh_bola_claim04')}
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim05')}</span>
          {t('fleet_veh_bola_claim06')}
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim07')}</span>
          {t('fleet_veh_bola_claim08')}
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim09')}</span>
        </MKTypography>
      </CardContent>
    </Card>
  );
}

function ImageGrid({ images }) {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const handleOpen = (image) => {
    setCurrentImage(image);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box
              component="img"
              src={image}
              alt={`Image ${index + 1}`}
              sx={{
                width: '100%',
                height: 'auto',
                cursor: 'pointer',
                borderRadius: 2
              }}
              onClick={() => handleOpen(image)}
            />
          </Grid>
        ))}
      </Grid>
      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ position: 'relative', outline: 'none' }}>
          <IconButton
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleClose}
          >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
          <Box
            component="img"
            src={currentImage}
            alt="Expanded Image"
            sx={{ maxWidth: '90vw', maxHeight: '90vh', borderRadius: 2 }}
          />
        </Box>
      </Modal>
    </>
  );
}

function AinaFleet({ isReservationOpen = false }) {
  const [valorHijo, setValorHijo] = useState('');
  const [appBarHeight, setAppBarHeight] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const appBarRef = useRef(null);
  const { t } = useTranslation();
  const [searchResult, setSearchResult] = useState('');

  const [estadoPrincipal, setEstadoPrincipal] = useState('');

  const manejarEstadoPrincipal = (nuevoEstado) => {
    setEstadoPrincipal(nuevoEstado);
  };

  const texts = [
    { title: t('protect_claim_title_01'), subtitle: t('protect_claim_subtitle_01') },
    { title: t('protect_claim_title_02'), subtitle: t('protect_claim_subtitle_02') },
    { title: t('protect_claim_title_03'), subtitle: t('protect_claim_subtitle_03') },
    { title: t('protect_claim_title_04'), subtitle: t('protect_claim_subtitle_04') }
  ];

  const textsCards = [
    { title0: "Coches - SUV" , title: t('fleet_coches_suv'), subtitle: t('fleet_coches_suv_subtitle'), color: "rgba(0, 0, 0, 0.00)", img: expocar03 },
    { title0: "Furgonetas pasajeros" , title: t('fleet_furgonetas_pasajeros'), subtitle: t('fleet_furgonetas_pasajeros_subtitle'), color: "rgba(0, 0, 0, 0.00)", img: expocar02 },
    //{ title0: "Furgones y camiones" , title: t('fleet_furgones_camiones'), subtitle: t('fleet_furgones_camiones_subtitle'), color: "rgba(0, 0, 0, 0.00)", img: expocar04 },
    { title0: "Furgonetas y camiones" , title: t('fleet_furgones_camiones'), subtitle: t('fleet_furgones_camiones_subtitle'), color: "rgba(0, 0, 0, 0.00)", img: expocar04 },
    { title0: "Vehículos con bola de remolque" , title: t('fleet_veh_bola'), subtitle: t('fleet_veh_bola_subtitle'), color: "rgba(0, 0, 0, 0.00)", img: expocar01 },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('');

  const toggleDrawer = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  const isTabletSize = useMediaQuery('(min-width:800px) and (max-width:1000px)');

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagesBackground.length);
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    console.log(nuevoValor);
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };

  const [isOpenReservation, setIsOpenReservation] = useState(isReservationOpen);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("reserve") === "true") {
      setIsOpenReservation(true);
    }
  }, [location]);
  

  const handleCategoriaClick = (categoria) => {
    setCategoriaSeleccionada(categoria);
    handleScrollToWhyAinaCar();
  };

  const scrollToRef = (ref) => {
    if (ref.current) {
      const yOffset = -150; // Ajuste para la posición
      const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const whyAinaCarRef = useRef(null);

  const handleScrollToWhyAinaCar = () => {
    scrollToRef(whyAinaCarRef);
  };


  return (
    <>
   
        <Karve02 isOpenReservation={isOpenReservation} reservationDrawer={reservationDrawer} 
                 estadoPrincipal={estadoPrincipal} />
    

      <Suspense fallback={<div>Loading...</div>}>
      </Suspense>

      <Box sx={{ backgroundColor: '#FFFFFF', minHeight: '100vh' }}>
        {//<Whatsapp />
        }
        <Box
          ref={appBarRef}
          style={{
            position: 'sticky',
            top: valorHijo ? '15px' : '20px',
            transition: valorHijo ? 'top 1s ease-in' : 'top 0.0s ease-out',
            zIndex: 3
          }}
        >
          <ResponsiveAppBar reservationDrawer={reservationDrawer} onCambio={manejarCambio} />
        </Box>
        <Box
          sx={{
            minHeight: "78vh",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), white), url(${protect1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`
          }}
        >
          <MKBox
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              textAlign: "center",
              alignItems: "center"
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`
                  }
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <MKBox sx={{ maxWidth: '100%' }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: 'Rodina-Regular',
                        textAlign: 'center',
                        lineHeight: 1,
                        fontSize: {
                          xs: '2.5rem',
                          sm: '3rem',
                          md: '4rem',
                          lg: '5rem'
                        },
                        textShadow: '0px 2px 60px rgba(0, 0, 0, 0.999)'
                      }}
                    >
                      {t('fleet_title')}
                    </MKTypography>
                    <MKBox sx={{ maxWidth: '70%', margin: '0 auto' }}>
                      <MKTypography
                        color="white"
                        variant="h3"
                        sx={{
                          fontFamily: 'Rodina-Regular',
                          textAlign: 'center',
                          lineHeight: 1.5,
                          fontSize: {
                            xs: '1.5rem',
                            sm: '2rem',
                            md: '2rem',
                            lg: '1.4rem'
                          },
                          textShadow: '0px 2px 60px rgba(0, 0, 0, 0.999)'
                        }}
                      >
                        {t('fleet_subtitle')}
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>

        <Container sx={{
          mt: {
            xs: -2,
            sm: -2,
            md: -2,
            lg: -14,
          }, mb: 8, backgroundColor: 'transparent'
        }}>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            {textsCards.map((text, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: isTabletSize ? 'column' : 'row' },
                    p: 1,
                    borderRadius: 2,
                    boxShadow: 'none',
                    overflow: 'hidden',
                    backgroundColor: text.color,
                  }}
                >
                  <Box sx={{ flexShrink: 0, width: { xs: '100%', sm: '100%', md: '100%', lg: isTabletSize ? '100%' : 400 }, }}>
                    <CardMedia
                      component="img"
                      image={text.img}
                      alt={text.title}
                      sx={{
                        width: '100%',
                        height: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto'},
                        objectFit: 'cover',
                        borderRadius: 2,
                      }}
                    />
                  </Box>
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'left',
                      paddingLeft: { xs: 1, sm: 2, md: 2, lg: isTabletSize ? 1 : 10 },
                      paddingTop: { xs: 2, sm: 2, md: 2, lg: isTabletSize ? 2 : 0 },
                      overflow: 'hidden',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ overflow: 'hidden' }}>
                      <MKTypography
                        mt={1}
                        mb={3}
                        color="#343434"
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {text.title}
                      </MKTypography>
                      <MKTypography
                        sx={{
                          fontSize: {
                            xs: '1.3rem',
                            sm: '1.3rem',
                            md: '1.3rem',
                            lg: isTabletSize ? '1.3rem' : '1rem',
                          },
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          wordWrap: 'break-word',
                        }}
                        color="#343434"
                      >
                        {text.subtitle}
                      </MKTypography>
                    </Box>
                    <Box mt={8} display="flex" justifyContent="flex-start">
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#d6061e", color: "#FFFFFF", boxShadow: 'none' }}
                        onClick={() => handleCategoriaClick(text.title0)}
                      >
                        Ver {text.title} de alquiler
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
                {index < textsCards.length - 1 && <Divider sx={{ mt: 5, mb: 5 }} />}
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container sx={{ backgroundColor: 'white' }}>
          <Grid mt={0} container justifyContent="center" spacing={3}>
            <Grid xs={12} sm={12}>
              <Productos refe={whyAinaCarRef} reservationDrawer={reservationDrawer} selectedCategoria={categoriaSeleccionada} manejarEstadoPrincipal={manejarEstadoPrincipal}/>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <MKBox bgColor="info">
        <MKBox>
          <Suspense fallback={<div>Loading...</div>}>
            <Footer pt={6} px={1} content={footerRoutes} />
          </Suspense>
        </MKBox>
      </MKBox>
    </>
  );
}

export default AinaFleet;
